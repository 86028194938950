.element-reserved {
	background-color: #E2C6FF;
}

.element-in-campaign {
	background-color: #ffcac6;
}

#chart-cities-amchart {
	> div {
		min-height: 200px;
	}
}

.segment-color {
	float: left;
	cursor: pointer;
	overflow: hidden;
}

.legend-setting {
	width: 16px;
	height: 16px;
}

.mapboxgl-popup {
	z-index: 2;
}

.item-template {
	box-shadow: 0 3px 5px 0 rgba(0, 0, 0, 0.2);

	.template-options {
		display: inline-block;
		position: absolute;
		right: -10px;
		top: -30px;
	}
}

.cursor-disabled {
	cursor: not-allowed;
}

.conditional {
	.ant-avatar {
		transition: all 300ms;
		color: #000000;
		background-color: #E9E9E9;
	}

	.active {
		background-color: #91d5ff;
	}

	.ant-col {
		&.ant-form-item-label {
			display: block;
			width: 100%;
			text-align: left;
		}
	}

	.weather {
		img {
			width: 20px;
			height: 20px;
			margin: 6px 0 0 6px;
		}
	}
}
