.MuiButtonBase-root {
	text-transform: none !important;

	&.Mui-disabled {
		background-color: #B7C6D4 !important;
		cursor: not-allowed !important;
	}

	&.btn-block {
		width: 100%;
	}
}
