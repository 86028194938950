.rs-form {
	.rs-form-group {
		.rs-form-control-wrapper {
			display: block;
			width: 100%;

			.rs-error-message-wrapper {
				right: 0;

				.rs-error-message-inner {
					white-space: normal;
				}
			}
		}
	}
}

.rs-input {
	width: 100% !important;
	border-radius: 4px;
}
