.message-item {
	padding: 20px 20px 0 20px;

	.indicator {
		width: 8px;
		height: 8px;
		position: absolute;
		right: -10px;
		top: -10px;
		border-radius: 50%;
		background-color: #aeb2b8;
		cursor: pointer;
		z-index: 2;

		&.active {
			background-color: #ea2c54;
		}
	}
}
