.ant-card, .ant-tabs {
	&.shadow {
		box-shadow: 0 1px 2px 0 rgba(33, 33, 33, 0.14), 0 0 1px 0 rgba(0, 0, 0, 0.14);
	}
}

.ant-tabs {
	&.background-white {
		background-color: #FFFFFF;
	}
}

.ant-table-wrapper {
	box-shadow: 0 1px 2px 0 rgba(33, 33, 33, 0.14), 0 0 1px 0 rgba(0, 0, 0, 0.14);

	&.box-shadow-none {
		box-shadow: none;
	}

	.ant-table {
		.ant-table-container {
			.ant-table-thead {
				font-size: 11px;

				.ant-table-column-sorters {
					width: 100%;

					> span {
						width: 100%;
						margin-right: -20px;

						&.ant-table-column-sorter {
							width: 12px;
							margin-right: 0;
						}
					}
				}
			}

			.ant-table-tbody {
				.ant-table-row {
					&.row-selected {
						background-color: #D2F0FF;
					}
				}
			}
		}
	}

	&.title-red, &.title-blue {
		.ant-table-title {
			color: #ffffff;
			font-size: 12px;
			padding: 13px;

			.table-icon-title {
				font-size: 18px;
				position: absolute;
				right: 15px;
				top: 8px;
			}
		}
	}

	&.title-red {
		.ant-table-title {
			background-color: red;
		}
	}

	&.title-blue {
		.ant-table-title {
			background-color: #070B3A;
		}
	}
}

.ant-dropdown-menu {
	.ant-dropdown-menu-item {
		&.item-approve {
			color: green;
		}

		&.item-reject {
			color: red;
		}
	}
}

.ant-row {
	&.ant-form-item {
		&.slider-range-tooltip {
			.ant-col.ant-form-item-label {
				margin-bottom: 35px;
			}
		}
	}
}

.ant-form {
	&.ant-form-vertical {
		&.custom-form {
			.ant-col.ant-form-item-label {
				padding-bottom: 2px;
			}
		}
	}
}

.ant-select-dropdown {
	z-index: 1080;
}

.ant-upload.ant-upload-drag {
	&.upload-no-padding {
		> .ant-upload.ant-upload-btn {
			padding: 0;
		}
	}
}

.remove-img {
	position: absolute;
	top: -26px;
	right: 0;
}

.ant-notification {
	z-index: 1100;
}

.ant-btn {
	&.btn-white {
		color: #FFFFFF;
		border: 1px solid #FFFFFF;
		background-color: transparent;
	}
}

.ant-picker-dropdown {
	z-index: 2200;
}

.ant-tooltip {
	z-index: 2001;

	&.ant-slider-tooltip {
		z-index: 2001;
	}
}

.ant-popover {
	&.ant-popconfirm {
		z-index: 2100;
	}
}

.ant-badge {
	.ant-badge-count {
		font-family: 'Proxima Nova Lt', sans-serif, Arial;
		font-weight: 600;
		font-style: normal;
	}
}

.ant-pagination {
	&.ant-table-pagination {
		&.ant-table-pagination-right {
			.ant-pagination-prev, .ant-pagination-item, .ant-pagination-next {
				margin-right: 3px !important;
				min-width: 28px !important;
				height: 28px !important;
				line-height: 26px !important;

				.nofollow {
					padding: 0 4px !important;
				}
			}
		}
	}
}


.ant-spin-nested-loading {
	> div {
		> .ant-spin {
			max-height: none;
		}
	}
}

.ant-input-number-suffix {
	position: absolute;
	top: 3px;
	right: 30px;
}

.ant-card {
	&.ant-card-head-title-white-space-normal {
		.ant-card-head {
			.ant-card-head-title {
				white-space: normal;
			}
		}
	}
}

.ant-select-dropdown {
	z-index: 3000;
}

.z-index-3000 {
	z-index: 3000;
}
