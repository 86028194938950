@for $i from 0 through 100 {
	//positivos
	.m-l-#{$i} {
		margin-left: #{$i}px !important;
	}
	.m-r-#{$i} {
		margin-right: #{$i}px !important;
	}
	.m-t-#{$i} {
		margin-top: #{$i}px !important;
	}
	.m-b-#{$i} {
		margin-bottom: #{$i}px !important;
	}
	.m-#{$i} {
		margin: #{$i}px;
	}
	.m-#{$i}i {
		margin: #{$i}px !important;
	}

	//negativos
	.m-l--#{$i} {
		margin-left: -#{$i}px !important;
	}
	.m-r--#{$i} {
		margin-right: -#{$i}px !important;
	}
	.m-t--#{$i} {
		margin-top: -#{$i}px !important;
	}
	.m-b--#{$i} {
		margin-bottom: -#{$i}px !important;
	}
	.m--#{$i} {
		margin: -#{$i}px;
	}

	//paddings
	.p-l-#{$i} {
		padding-left: #{$i}px;
	}
	.p-r-#{$i} {
		padding-right: #{$i}px;
	}
	.p-t-#{$i} {
		padding-top: #{$i}px;
	}
	.p-b-#{$i} {
		padding-bottom: #{$i}px;
	}
	.p-l-#{$i}i {
		padding-left: #{$i}px !important;
	}
	.p-r-#{$i}i {
		padding-right: #{$i}px !important;
	}
	.p-t-#{$i}i {
		padding-top: #{$i}px !important;
	}
	.p-b-#{$i}i {
		padding-bottom: #{$i}px !important;
	}
	.p-#{$i} {
		padding: #{$i}px;
	}
	.p-#{$i}i {
		padding: #{$i}px !important;
	}
}

@for $i from 8 through 40 {
	.fs-#{$i} {
		font-size: #{$i}px !important;
	}
}

.mlr-0 {
	margin-left: 0;
	margin-right: 0;
}

.cursor {
	cursor: pointer;
}

.text-right {
	text-align: right;
}

.text-center {
	text-align: center;
}

body {
	font-family: 'Proxima Nova Rg', sans-serif;
	font-weight: normal;
	font-style: normal;
	color: $font-color-primary-text;
	background-color: $background-color-body;
}

.position-relative {
	position: relative;
}

a {
	&.default {
		color: $font-color-primary-text !important;
	}
}

h4 {
	.small {
		font-size: 18px;
	}
}

.spi-loading {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	padding: 20px;
	text-align: center;
	display: flex;
	z-index: 2000;
	background: rgba(255, 255, 255, 0.7);

	.ant-spin {
		margin: auto;
	}
}
